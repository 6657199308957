import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ServiceTemplates from "../../../components/Roster/Services/ServiceTemplates/ServiceTemplates";
import MonthSelector from "../../../components/Roster/MonthSelector/MonthSelector";
import EditGroupPanel from "../../../components/Roster/Services/EditGroupPanel/EditGroupPanel";
import Calendar from "../../../components/Roster/Services/Calendar/Calendar";
import InsertOptions from "../../../components/Roster/Services/InsertOptions/InsertOptions";

import { getDateObjectFromMySqlDate, getMySqlDateFromDateObject } from "../../../shared/functions";
import { addService, addServiceRepetitive, getRoster, deleteService, resolveGroup } from "../../../features/roster/rosterSlice";
import { getPublicHolidaysOfRosterPeriod } from "../../../features/publicHoliday/publicHolidaySlice";
import { ask, clear } from "../../../features/ask/askSlice";
import * as askTypes from "../../../shared/askTypes";
import ServiceTemplatesFix from "../../../components/Roster/Services/ServiceTemplatesFix/ServiceTemplatesFix";
import { getRosterTabs, serviceTemplateTypes } from "../../../shared/globals";
import styles from "./Services.module.css";
import { getServiceTemplates, selectServiceTemplate } from "../../../features/serviceTemplate/serviceTemplateSlice";
import Panel from "../../../hoc/Panel/Panel";

const Services = () => {
    const [group, setGroup] = useState(null);
    const [insertOptions, setInsertOptions] = useState(null);

    const serviceTemplateId = useSelector((state) => state.serviceTemplate.selected);

    const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
    const { serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);
    const answer = useSelector((state) => state.ask.answer);
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
    const rosterPeriode = rosterPeriodId ? rosterPeriodes.find((entry) => entry.id === rosterPeriodId) : null;
    const rosterDays = useSelector((state) => state.roster.days);
    const publicHolidays = useSelector((state) => state.publicHoliday.roster);
    const rosterState = useSelector((state) => state.roster.state);

    const dispatch = useDispatch();

    useEffect(() => {
        if (rosterPeriodId && serviceCircleId) {
            dispatch(getRoster(rosterPeriodId));
            dispatch(getPublicHolidaysOfRosterPeriod(rosterPeriodId));
            dispatch(getServiceTemplates({ serviceCircleId, rosterPeriodId }));
        }
    }, [dispatch, rosterPeriodId, serviceCircleId]);

    useEffect(() => {
        if (answer) {
            if (answer.type === askTypes.RESOLVE_GROUP && answer.value !== null) {
                console.log("RESOLVE_GROUP");
                dispatch(resolveGroup({ rosterPeriodId, id: answer.value }));
                dispatch(clear());
            }
        }
    }, [answer, dispatch, rosterPeriodId]);

    if (!rosterPeriode) {
        return null;
    }

    const askResolveGroup = (id) => {
        dispatch(
            ask({
                type: askTypes.RESOLVE_GROUP,
                title: `Gruppe auflösen`,
                question: `Möchtest Du die Gruppe wirklich auflösen?`,
                buttons: [
                    { label: "Ja, auflösen", answer: id },
                    { label: "Abbrechen", answer: null }
                ]
            })
        );
    };

    const askInsertOptions = (date) => {
        const { name, type } = serviceTemplates.find((entry) => entry.id === serviceTemplateId);
        const title = type === serviceTemplateTypes.bookable ? `Dienst «${name}» einfügen` : `Fix-Dienst «${name}» einfügen`;
        setInsertOptions({
            date,
            title
        });
    };

    const insert = (mode) => {
        const startDate = getMySqlDateFromDateObject(insertOptions.date);
        if (mode === "single") {
            dispatch(addService({ rosterPeriodId, data: { startDate, serviceTemplateId } }));
        } else {
            dispatch(addServiceRepetitive({ rosterPeriodId, data: { startDate, serviceTemplateId, mode } }));
        }
        setInsertOptions(null);
    };

    const deleteServiceFromRoster = (id) => {
        dispatch(deleteService(id));
    };

    const addToGroup = (id) => {
        const newService = rosterDays.reduce((service, day) => {
            const addService = day.services.find((service) => service.id === id);
            if (addService) {
                const timeStamps = getTimeStampsOfService(addService.serviceTemplateId, day.date);
                service = {
                    id: addService.id,
                    serviceTemplateId: addService.serviceTemplateId,
                    date: day.date,
                    ...timeStamps
                };
            }
            return service;
        }, null);
        setGroup((prev) => ({ ...prev, entries: [...prev.entries, newService] }));
    };

    const removeFromGroup = (id) => {
        setGroup((prev) => ({ ...prev, entries: prev.entries.filter((entry) => entry.id !== id) }));
    };

    const getTimeStampsOfService = (id, date) => {
        const serviceTemplate = serviceTemplates.find((entry) => entry.id === id);
        const startTimeStamp = getDateObjectFromMySqlDate(date);
        const endTimeStamp = new Date(startTimeStamp.getTime());
        startTimeStamp.setHours(Math.floor(serviceTemplate.start));
        startTimeStamp.setMinutes(Math.round((serviceTemplate.start % 1) * 60));
        if (serviceTemplate.start > serviceTemplate.end) {
            endTimeStamp.setDate(endTimeStamp.getDate() + 1);
        }
        endTimeStamp.setHours(Math.floor(serviceTemplate.end));
        endTimeStamp.setMinutes(Math.round((serviceTemplate.end % 1) * 60));

        return { startTimeStamp: startTimeStamp.getTime(), endTimeStamp: endTimeStamp.getTime() };
    };

    const editGroup = (id) => {
        const groupServices = rosterDays.reduce((services, day) => {
            const addServices = day.services
                .filter((service) => service.id === id || service.groupId === id)
                .map((service) => {
                    const timeStamps = getTimeStampsOfService(service.serviceTemplateId, day.date);
                    return {
                        id: service.id,
                        serviceTemplateId: service.serviceTemplateId,
                        date: day.date,
                        ...timeStamps
                    };
                });
            return [...services, ...addServices];
        }, []);
        const groupId = groupServices.find((service) => service.id === id)?.id || id;
        setGroup({
            groupId,
            entries: [...groupServices]
        });
    };

    if (group) {
        console.log(group);
    }

    const select = (id) => {
        dispatch(selectServiceTemplate(id));
    };

    const actions = {
        askInsertOptions: serviceTemplateId ? askInsertOptions : null,
        deleteService: deleteServiceFromRoster,
        editGroup,
        addToGroup,
        removeFromGroup,
        askResolveGroup
    };

    const rosterPeriodeStart = getDateObjectFromMySqlDate(rosterPeriode.start);
    const rosterPeriodeEnd = getDateObjectFromMySqlDate(rosterPeriode.end);
    const calendar = serviceTemplates && rosterDays ? <Calendar rosterDays={rosterDays} rosterPeriodeStart={rosterPeriodeStart} rosterPeriodeEnd={rosterPeriodeEnd} actions={actions} publicHolidays={publicHolidays} group={group} /> : null;

    return (
        <Panel title="Dienstplan | Dienste" rosterPeriode categorieLevels={4} tabs={getRosterTabs(rosterState)} padding>
            <div className={styles.roster}>
                <div>
                    <div className={styles.monthSelector}>
                        <MonthSelector start={rosterPeriodeStart} end={rosterPeriodeEnd} />
                    </div>
                    {calendar}
                </div>
                <div className="sidePanel">
                    {group === null ? (
                        <Fragment>
                            <ServiceTemplates serviceCircleId={serviceCircleId} rosterPeriodId={rosterPeriodId} select={select} selectedId={serviceTemplateId} />
                            {rosterPeriode.serviceAlwaysInMedicalPracticeId > 0 && <ServiceTemplatesFix rosterPeriodId={rosterPeriodId} select={select} selectedId={serviceTemplateId} />}
                        </Fragment>
                    ) : (
                        <EditGroupPanel cancel={() => setGroup(null)} group={group} />
                    )}
                </div>
                {insertOptions && <InsertOptions title={insertOptions.title} insert={insert} cancel={() => setInsertOptions(null)} />}
            </div>
        </Panel>
    );
};

export default Services;
