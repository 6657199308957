import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./ServiceTemplatesEditor.module.css";
import Window from "../../../../../hoc/Window/Window";
import Panel from "../../../../../hoc/Panel/Panel";
import FlyingError from "../../../../FlyingError/FlyingError";
import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import { createServiceTemplate, updateServiceTemplate } from "../../../../../features/serviceTemplate/serviceTemplateSlice";
import { clearError, setError, clearCloseOnExit } from "../../../../../features/message/messageSlice";
import InputTime from "../../../../../ui/InputTime/InputTime";
import { floatTimeToTime } from "../../../../../shared/functions";
import Note from "../../../../Note/Note";
import { serviceTemplateTypes } from "../../../../../shared/globals";
import FooterButtons from "../../../../../ui/FooterButtons/FooterButtons";

const newServiceTemplate = {
	id: "",
	type: serviceTemplateTypes.bookable,
	name: "",
	start: "",
	end: "",
	points: {
		monday: "",
		tuesday: "",
		wednesday: "",
		thursday: "",
		friday: "",
		saturday: "",
		sunday: "",
		publicHoliday: ""
	}
};

const pointsNames = [
	{ key: "monday", name: "Montag" },
	{ key: "tuesday", name: "Dienstag", automaticInsert: true },
	{ key: "wednesday", name: "Mittwoch", automaticInsert: true },
	{ key: "thursday", name: "Donnerstag", automaticInsert: true },
	{ key: "friday", name: "Freitag", automaticInsert: true },
	{ key: "saturday", name: "Samstag" },
	{ key: "sunday", name: "Sonntag" },
	{ key: "publicHoliday", name: "Feiertag" }
];

const ServiceTemplatesEditor = ({ id, close, rosterPeriodId }) => {
	const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
	const bookableServiceTemplates = serviceTemplates.filter((entry) => entry.type === serviceTemplateTypes.bookable && entry.rosterPeriodId === rosterPeriodId);

	const closeOnExit = useSelector((state) => state.message.closeOnExit);
	const color = useSelector((state) => state.values.color);
	const editTemplate = bookableServiceTemplates.find((entry) => entry.id === id) || newServiceTemplate;
	const [serviceTemplate, setServiceTemplate] = useState({ ...editTemplate });
	const [fieldErrors, setFieldErrors] = useState(null);
	const [pointsEdited, setPointsEdited] = useState(id !== "");
	const dispatch = useDispatch();

	useEffect(() => {
		if (closeOnExit) {
			dispatch(clearCloseOnExit());
			close();
		}
	}, [closeOnExit, dispatch, close]);

	const setName = (e) => {
		const value = e.target.value;
		setServiceTemplate((prev) => ({ ...prev, name: value }));
		if (
			bookableServiceTemplates
				.filter((entry) => entry.id !== id)
				.map((entry) => entry.name)
				.includes(value)
		) {
			setFieldErrors((prev) => ({ ...prev, name: "Dieser Name wird bereits verwendet" }));
		} else if (value.trim().length < 3) {
			setFieldErrors((prev) => ({ ...prev, name: "Der Name muss mindestens 3 Zeichen aufweisen" }));
		} else if (fieldErrors && fieldErrors.name) {
			delete fieldErrors.name;
		}
	};

	const setPoints = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;
		if (value.match(/^\d{0,2}\.?\d{0,2}$/)) {
			if (fieldname === "monday" && !pointsEdited && id === "") {
				const additionalValues = {};
				pointsNames
					.filter((point) => point.automaticInsert)
					.forEach((point) => {
						additionalValues[point.key] = value;
					});
				setServiceTemplate((prev) => ({ ...prev, points: { ...prev.points, [fieldname]: value, ...additionalValues } }));
			} else {
				setPointsEdited(true);
				setServiceTemplate((prev) => ({ ...prev, points: { ...prev.points, [fieldname]: value } }));
			}
		}
	};

	const save = () => {
		const errors = [];
		dispatch(clearError());
		if (!serviceTemplate.name.trim().length) {
			errors.push("Bitte gib dem Dienst einen Namen.");
		}
		if (serviceTemplate.start === "" || serviceTemplate.end === "") {
			errors.push("Bitte gib eine gültige Dienstzeit ein.");
		}
		let pointError = false;
		pointsNames.forEach((point) => {
			if (!pointError && !serviceTemplate.points[point.key].toString().replace(".", "").length) {
				errors.push("Bitte gib die Dienstpunkte für alle Tage an.");
				pointError = true;
			}
		});

		if (errors.length) {
			dispatch(setError(errors));
		} else {
			if (serviceTemplate.id === "") {
				const usedColors = bookableServiceTemplates.map((entry) => entry.colorId);
				const possibleColors = color.filter((color) => !usedColors.includes(color.id));
				const colorId = possibleColors.length > 0 ? possibleColors[0].id : "0";
				dispatch(createServiceTemplate({ rosterPeriodId, data: { ...serviceTemplate, colorId } }));
			} else {
				dispatch(updateServiceTemplate({ id, data: { ...serviceTemplate } }));
			}
		}
	};

	const setStart = (value) => {
		setServiceTemplate((prev) => ({ ...prev, start: value }));
	};

	const setEnd = (end) => {
		setServiceTemplate((prev) => ({ ...prev, end }));
	};

	const duration = serviceTemplate.start !== "" && serviceTemplate.end !== "" ? floatTimeToTime(serviceTemplate.end > serviceTemplate.start ? serviceTemplate.end - serviceTemplate.start : serviceTemplate.end + 24 - serviceTemplate.start) : "-";

	return (
		<Window>
			<Panel title={serviceTemplate.id === "" ? "Neuer Dienst" : "Dienst bearbeiten"} size="small" errors padding>
				<label className={styles.templateName}>
					<span>Name</span>
					<FlyingError error={fieldErrors ? fieldErrors.name : null}>
						<input className={(fieldErrors && fieldErrors.name ? styles.fieldError : "") + " " + styles.name} type="text" maxLength={32} value={serviceTemplate.name} onChange={setName} />
					</FlyingError>
				</label>
				{serviceTemplate.locked && <Note marginTop>Dieser Dienst kann nicht mehr verändert werden, da er in der Vergangenheit verwendet wurde.</Note>}
				<div className={styles.days}>
					<SmallPanel title="Dienstzeit">
						<div className={styles.smallPanelContent}>
							<label className={styles.points}>
								<span>Beginn</span>
								<InputTime time={serviceTemplate.start} set={setStart} disabled={serviceTemplate.locked} />
								Uhr
							</label>
							<label className={styles.points}>
								<span>Ende</span>
								<InputTime time={serviceTemplate.end} set={setEnd} disabled={serviceTemplate.locked} />
								Uhr
							</label>
							<div className={styles.points}>
								<span>Dauer</span>
								<div>{duration}</div>
							</div>
						</div>
					</SmallPanel>
					<SmallPanel title="Dienstpunkte">
						<div className={styles.smallPanelContent}>
							{pointsNames.map((point) => (
								<label key={point.key} className={styles.points}>
									<span>{point.name}</span>
									<FlyingError error={fieldErrors ? fieldErrors[point.key] : null}>
										<input className={fieldErrors && fieldErrors[point.key] ? styles.fieldError : ""} type="text" maxLength={4} name={point.key} value={serviceTemplate.points[point.key]} onChange={setPoints} disabled={serviceTemplate.locked} />
									</FlyingError>
								</label>
							))}
						</div>
					</SmallPanel>
				</div>
				<FooterButtons paddingTop>
					<button type="button" onClick={() => close()}>
						Abbrechen
					</button>
					<button type="button" onClick={() => save()} disabled={fieldErrors && fieldErrors.name}>
						Speichern
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default ServiceTemplatesEditor;
