import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getRosterSubscribers, getPossibleRosterDoctors, setRosterSubscriptionId, clearRosterSubscriptionId, setPersonId, clearPersonId } from "../../../features/roster/rosterSlice";

import styles from "./Doctors.module.css";
//import SortableTable from "../../SortableTable/SortableTable";
import EditSubscription from "../../../components/Roster/Doctors/EditSubscription/EditSubscription";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import Note from "../../../components/Note/Note";
import { getRosterTabs, nfdStates } from "../../../shared/globals";
import * as routes from "../../../shared/routes";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import ShowPerson from "../../../components/Display/ShowPerson/ShowPerson";
import { getPersons } from "../../../features/persons/personsSlice";
import NewSortableTable from "../../../components/NewSortableTable/NewSortableTable";
import Panel from "../../../hoc/Panel/Panel";
import Points from "../../../components/Display/Points/Points";

const subscribedFields = [
    {
        key: "lastname",
        name: "Arzt",
        showField: (row) => <ShowPerson id={row.personId} />
    },
    {
        key: "minAmount",
        name: "Pensum",
        type: "number",
        showField: (row) => (row.minAmount === undefined ? "" : row.minAmount + "%"),
        align: "right"
    },
    {
        key: "balance",
        name: "Punktesaldo",
        type: "number",
        showField: (row) => <Points>{row.balance}</Points>,
        align: "right"
    },
    {
        key: "canLockOutEvening",
        name: "Kein Abenddienst",
        type: "boolean",
        showField: (row) => (row.canLockOutEvening === undefined ? "" : row.canLockOutEvening ? "Ja" : "Nein"),
        align: "right"
    },
    {
        key: "lockWeekDays",
        name: "Sperrtage/Woche",
        type: "number",
        align: "right"
    },
    {
        key: "lockWeekendDays",
        name: "Wochenend-Sperrtage/Woche",
        type: "number",
        align: "right"
    }
];

const availableDoctorFields = [
    {
        key: "lastname",
        name: "Arzt",
        showField: (row) => <ShowPerson id={row.personId} />,
        className: styles.available_doctor
    }
];

const Doctors = () => {
    const { rosterPeriodId } = useSelector((state) => state.category.selected);
    const subscribers = useSelector((state) => state.roster.subscribers);
    const doctors = useSelector((state) => state.roster.doctors);
    const rosterSubscriptionId = useSelector((state) => state.roster.rosterSubscriptionId);
    const personId = useSelector((state) => state.roster.personId);
    const rosterState = useSelector((state) => state.roster.state);
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState("");
    const [orderBy, setOrderBy] = useState(null);
    const [orderDoctorsBy, setOrderDoctorsBy] = useState(null);
    const [showOnlyDoctorsOfServiceCircle, setShowOnlyDoctorsOfServiceCircle] = useState(true);

    useEffect(() => {
        dispatch(getRosterSubscribers(rosterPeriodId));
        dispatch(getPossibleRosterDoctors(rosterPeriodId));
        dispatch(getPersons());
    }, [dispatch, rosterPeriodId]);

    useEffect(() => {
        if (orderBy === null) {
            setOrderBy({
                key: subscribedFields[0].key,
                order: "asc"
            });
        }
        if (orderDoctorsBy === null) {
            setOrderDoctorsBy({
                key: availableDoctorFields[0].key,
                order: "asc"
            });
        }
    }, [orderBy, orderDoctorsBy]);

    useEffect(() => {
        if (rosterState) {
            setShowOnlyDoctorsOfServiceCircle(rosterState.serviceByDoctor);
        }
    }, [rosterState]);

    if (!subscribers || !doctors || !orderBy || !rosterState) {
        return null;
    }

    const filterActive = () => {
        return filterText.length > 2;
    };

    const contains = (row) => {
        if (!filterActive()) return true;
        const searchStrings = filterText.split(" ");

        const found = searchStrings.map((search) => {
            search = search.toLowerCase();
            if (row.firstname.toLowerCase().indexOf(search) > -1 || row.lastname.toLowerCase().indexOf(search) > -1) {
                return 1;
            } else {
                return 0;
            }
        });
        const foundCount = found.reduce((a, b) => a + b, 0);
        return foundCount === searchStrings.length;
    };

    const changeOrderBy = (key) => {
        if (orderBy.key === key) {
            setOrderBy((prev) => ({ ...prev, order: prev.order === "asc" ? "desc" : "asc" }));
        } else {
            setOrderBy((prev) => ({ ...prev, key }));
        }
    };

    const changeOrderDoctorsBy = (key) => {
        if (orderDoctorsBy.key === key) {
            setOrderDoctorsBy((prev) => ({ ...prev, order: prev.order === "asc" ? "desc" : "asc" }));
        } else {
            setOrderDoctorsBy((prev) => ({ ...prev, key }));
        }
    };

    const editSubscription = (row) => {
        dispatch(setRosterSubscriptionId(row.id));
    };

    const newSubscription = (row) => {
        dispatch(setPersonId(row.personId));
    };

    const filteredSubscribers = [...subscribers]
        .filter((person) => contains(person))
        .map((person) => ({ ...person, error: person.error || doctors.find((doctor) => doctor.personId === person.personId) === undefined, minAmount: person.minAmount.toString() + (person.maxAmount ? ` – ${person.maxAmount}` : "") }));
    const addedPersonIds = subscribers.map((subscription) => subscription.personId);

    const getSubscriptionData = () => {
        const data = subscribers.find((entry) => entry.id === rosterSubscriptionId);
        return { ...data, notInServiceCircle: doctors.find((doctor) => doctor.personId === data.personId) === undefined };
    };

    const changeShowOnlyDoctors = (hide) => {
        setShowOnlyDoctorsOfServiceCircle(!hide);
    };

    const filteredDoctors = [...doctors].filter((person) => !addedPersonIds.includes(person.personId) && contains(person) && (!showOnlyDoctorsOfServiceCircle || person.hasMedicalPracticeInServiceCircle));

    const subscribersInfo = filterActive() ? `${filteredSubscribers.length} ${filteredSubscribers.length === 1 ? "Arzt" : "Ärzte"} gefunden` : `${filteredSubscribers.length} ${filteredSubscribers.length === 1 ? "Arzt" : "Ärzte"} aufgenommen im Dienstplan`;
    const doctorsInfo = filterActive() ? `${filteredDoctors.length} ${filteredDoctors.length === 1 ? "Arzt" : "Ärzte"} gefunden` : `${filteredDoctors.length} ${filteredDoctors.length === 1 ? "Arzt" : "Ärzte"} verfügbar`;

    return (
        <Panel title="Dienstplan | Ärzte" size="xLarge" className={styles.panel} rosterPeriode categorieLevels={4} tabs={getRosterTabs(rosterState)}>
            <div className={styles.search}>
                <SearchFilter filterText={filterText} setFilterText={setFilterText} placeholder="Arzt suchen..." />
            </div>
            <div className={styles.resultInfo}>{subscribersInfo}</div>
            <div className={styles.table}>
                <NewSortableTable data={filteredSubscribers} fields={subscribedFields} changeOrder={changeOrderBy} orderBy={orderBy} rowClickAction={editSubscription} />
            </div>
            <div className={styles.resultInfo}>
                {doctorsInfo}
                {rosterState.serviceByDoctor && (
                    <div className={styles.showOnlyDoctors}>
                        <CheckBox checked={!showOnlyDoctorsOfServiceCircle} set={changeShowOnlyDoctors} name="Alle Ärzte anzeigen, unabhängig davon ob diese in einer Praxis Notfalldienst leisten, welche sich im Dienstkreis befindet" />{" "}
                    </div>
                )}
            </div>

            <div className={styles.table}>
                <NewSortableTable data={filteredDoctors} fields={availableDoctorFields} changeOrder={changeOrderDoctorsBy} orderBy={orderDoctorsBy} rowClickAction={newSubscription} />
            </div>
            {rosterSubscriptionId && <EditSubscription close={() => dispatch(clearRosterSubscriptionId())} subscriptionData={getSubscriptionData()} rosterPeriodId={rosterPeriodId} serviceByDoctor={rosterState.serviceByDoctor} />}
            {personId && <EditSubscription close={() => dispatch(clearPersonId())} subscriptionData={doctors.find((entry) => entry.personId === personId)} rosterPeriodId={rosterPeriodId} serviceByDoctor={rosterState.serviceByDoctor} />}
            <div className={styles.notes}>
                <Note>
                    <strong>Falls der gewünschte Arzt hier nicht angezeigt wird, prüfe folgendes in dessen Profil:</strong>
                    <ul>
                        <li>Setze den Notfalldienst-Status auf «{nfdStates.find((entry) => entry.key === "active_service").name}».</li>
                        <li>Füge unter «Konto» die entsprechende Dienstart hinzu.</li>
                        <li>Stelle sicher, dass ihm mindestens eine Praxis im entsprechenden Dienstkreis zugeordnet ist und dass er in dieser Notfalldienst leistet.</li>
                    </ul>
                    {rosterState && rosterState.serviceByDoctor && (
                        <Fragment>
                            <br />
                            Stelle zudem sicher, dass am ersten Tag des Dienstplanes einen <Link to={routes.GEOGRAPHY}>Basiskarte</Link> hinterlegt ist.
                        </Fragment>
                    )}
                </Note>
            </div>
        </Panel>
    );
};

export default Doctors;
