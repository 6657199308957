import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Edit } from "../../../../assets/images/icon_edit.svg";

import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import DateRangeSelector from "../../../DateRangeSelector/DateRangeSelector";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";
import RadioSelect from "../../../../ui/RadioSelect/RadioSelect";
import MedicalPracticeChooser from "../../../MedicalPracticeChooser/MedicalPracticeChooser";

import { getDateObjectFromMySqlDate, getMySqlDateFromDateObject } from "../../../../shared/functions";
import { createRosterPeriode, getLastUsedDateOfRosterPeriod, updateRosterPeriode } from "../../../../features/category/categorySlice";

import styles from "./EditRosterPeriod.module.css";
import { getMedicalPractices } from "../../../../features/medicalPractices/medicalPracticesSlice";

const service = [
	{ id: "doctor", value: "Beim ausführenden Arzt" },
	{ id: "fixLocation", value: "In folgender Praxis:" }
];

const EditRosterPeriod = ({ id, cancel, rosterPeriodes, serviceCircleId }) => {
	const [period, setPeriode] = useState(null);
	const dispatch = useDispatch();
	const rosterPeriod = id ? rosterPeriodes.find((rosterPeriod) => rosterPeriod.id === id) : null;
	const lastUsedDate = rosterPeriod ? (rosterPeriod.lastUsedDate ? getDateObjectFromMySqlDate(rosterPeriod.lastUsedDate) : null) : null;
	const endDate = rosterPeriod ? (rosterPeriod.end ? getDateObjectFromMySqlDate(rosterPeriod.end) : null) : null;
	const serviceAlwaysInMedicalPracticeId = rosterPeriod ? rosterPeriod.serviceAlwaysInMedicalPracticeId : 0;
	const [serviceBy, setServiceBy] = useState(serviceAlwaysInMedicalPracticeId > 0 ? "fixLocation" : "doctor");
	const [chooseMedicalPractice, setChooseMedicalPractice] = useState(false);
	const [medicalPracticeId, setMedicalPracticeId] = useState(serviceAlwaysInMedicalPracticeId);
	const medicalPractices = useSelector((state) => state.medicalPractices.entries);
	const medicalPractice = medicalPracticeId > 0 && medicalPractices ? medicalPractices.find((entry) => entry.id === medicalPracticeId) : null;

	const startDate = id ? getDateObjectFromMySqlDate(rosterPeriod.start) : rosterPeriodes && rosterPeriodes.length > 0 ? getDateObjectFromMySqlDate(rosterPeriodes[0].end) : null;
	if (startDate && !id) {
		startDate.setDate(startDate.getDate() + 1);
	}
	useEffect(() => {
		if (id) {
			dispatch(getLastUsedDateOfRosterPeriod(id));
		}
	}, [id, dispatch]);

	useEffect(() => {
		if (!medicalPractices && medicalPracticeId > 0) {
			dispatch(getMedicalPractices());
		}
	}, [dispatch, medicalPractices, medicalPracticeId]);

	const save = () => {
		const start = getMySqlDateFromDateObject(period.start);
		const end = getMySqlDateFromDateObject(period.end);
		if (id) {
			const data = { start, end, medicalPracticeId };
			dispatch(updateRosterPeriode({ rosterPeriodId: id, data }));
		} else {
			dispatch(createRosterPeriode({ serviceCircleId, start, end, medicalPracticeId }));
		}
		cancel();
	};

	const changeServiceBy = (value) => {
		setServiceBy(value);
		if (value === "fixLocation" && !medicalPractice) {
			setChooseMedicalPractice(true);
		} else {
			setMedicalPracticeId(0);
		}
	};

	return (
		<Fragment>
			<Window>
				<Panel title={id ? "Dienstplan Enddatum ändern" : "Neuer Dienstplan"} size="small" padding>
					<DateRangeSelector startDate={startDate} endDate={endDate} minEndDate={lastUsedDate} setPeriode={setPeriode} startDateProtected={startDate !== null} />
					<RadioSelect data={service} label="Durchführung Notfalldienst" keyName="id" valueName="value" selected={serviceBy} setSelected={changeServiceBy} />
					{serviceBy === "fixLocation" && (
						<div className={styles.medicalPractice}>
							{medicalPractice && (
								<Fragment>
									{medicalPractice.name}, {medicalPractice.city} <Edit onClick={() => setChooseMedicalPractice(true)} />
								</Fragment>
							)}
						</div>
					)}
					<FooterButtons paddingTop>
						<button type="button" onClick={cancel}>
							Abbrechen
						</button>
						<button type="button" onClick={save} disabled={!period || !period.start || !period.end}>
							OK
						</button>
					</FooterButtons>
				</Panel>
			</Window>
			{chooseMedicalPractice && <MedicalPracticeChooser medicalPracticeId={medicalPracticeId} setMedicalPracticeId={setMedicalPracticeId} close={() => setChooseMedicalPractice(false)} />}
		</Fragment>
	);
};

export default EditRosterPeriod;
