import { useSelector } from "react-redux";

import styles from "./Persons.module.css";
import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import ShowPerson from "../../../../Display/ShowPerson/ShowPerson";

const Persons = () => {
    const doctorInputs = useSelector((state) => state.roster.doctorInputs);
    const doctors = useSelector((state) => state.roster.doctors);

    const getDoctorName = (id) => {
        const doctor = doctors.find((entry) => entry.personId === id);
        if (doctor) {
            return doctor.lastname + " " + doctor.firstname;
        }
        return "???";
    };

    const sortedDoctorInputs = [...doctorInputs].sort((a, b) => getDoctorName(a.personId).localeCompare(getDoctorName(b.personId)));
    return (
        <SmallPanel title="Ärzte">
            <ul className={styles.list}>
                {sortedDoctorInputs.map((person) => (
                    <li key={person.personId}>
                        <ShowPerson id={person.personId} />
                    </li>
                ))}
            </ul>
        </SmallPanel>
    );
};

export default Persons;
