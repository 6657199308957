import { monthNames } from "./globals";

export const startTimeToPercent = (time) => {
	return (100 / 24) * time;
};

export const endTimeToPercent = (time) => {
	return 100 - (100 / 24) * time;
};

export const floatTimeToTime = (time) => {
	const minutes = Math.round((time % 1) * 60);
	return Math.floor(time).toString() + ":" + (100 + minutes).toString().substring(1);
};

export const displayTimeRange = (startTime, endTime, clock = true) => {
	return floatTimeToTime(startTime) + " – " + floatTimeToTime(endTime) + (clock ? " Uhr" : "");
};

export const formatDate = (date) => {
	if (!date) {
		return null;
	}
	return date.getDate() + ". " + monthNames[date.getMonth()] + " " + date.getFullYear();
};

export const formatDateTime = (date) => {
	if (!date) {
		return null;
	}
	return formatDate(date) + ", " + formatTime(date);
};

export const formatTime = (date) => {
	if (!date) {
		return null;
	}
	return `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")} Uhr`;
};

export const getDateObjectFromMySqlDate = (mysqlDate) => {
	if (mysqlDate && mysqlDate.match(/^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2})?$/)) {
		const [date, time] = mysqlDate.split(" ");
		const [year, month, day] = date.split("-");
		const result = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
		if (time) {
			const [hour, minute, second] = time.split(":");
			result.setHours(parseInt(hour, 10), parseInt(minute, 10), parseInt(second, 10));
		}
		return result;
	} else {
		return null;
	}
};

export const isPast = (date) => {
	if (!date) {
		return true;
	}
	return date < new Date();
};

export const getMySqlDateFromDateObject = (date) => {
	return date.getFullYear().toString() + "-" + (date.getMonth() + 101).toString().slice(1) + "-" + date.getDate().toString().padStart(2, "0");
};

export const getMySqlDateTimeFromDateObject = (date) => {
	return getMySqlDateFromDateObject(date) + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0");
};

export const getMySqlDateFromDayMonthYear = (day, month, year) => {
	return year.toString() + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0");
};

export const hasWhiteText = (color) => {
	return parseInt(color.slice(0, 2), 16) + parseInt(color.slice(2, 4), 16) + parseInt(color.slice(4, 6), 16) < 384;
};

export const getNfdLoadFromWorkLoad = (workload) => {
	if (workload < 31) {
		return 30;
	}
	if (workload < 61) {
		return 60;
	}
	if (workload < 81) {
		return 80;
	}
	return 100;
};

export const combineColors = (colors) => {
	const resultColor = {
		r: 0,
		g: 0,
		b: 0
	};
	colors.forEach((color) => {
		resultColor.r += parseInt(color.slice(0, 2), 16) / colors.length;
		resultColor.g += parseInt(color.slice(2, 4), 16) / colors.length;
		resultColor.b += parseInt(color.slice(4, 6), 16) / colors.length;
	});
	return Math.round(resultColor.r).toString(16) + Math.round(resultColor.g).toString(16) + Math.round(resultColor.b).toString(16);
};

export const dateIsInFuture = (day, month, year) => {
	const date = new Date(year, month - 1, day);
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	return date.getTime() > now.getTime();
};

export const dateIsToday = (day, month, year) => {
	const date = new Date(year, month - 1, day);
	const now = new Date();
	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric"
	};
	return date.toLocaleDateString(undefined, options) === now.toLocaleDateString(undefined, options);
};

export const dateExists = (date) => {
	if (date.match(/^(\d){4}-(\d){1,2}-(\d){1,2}$/)) {
		const [year, month, day] = date.split("-");
		const checkDate = new Date(year, month - 1, day);
		return parseInt(year, 10) === checkDate.getFullYear() && parseInt(month, 10) - 1 === checkDate.getMonth() && parseInt(day, 10) === checkDate.getDate();
	}
	return false;
};

export const dateToInt = (date) => {
	return parseInt(date.getFullYear().toString() + (date.getMonth() + 101).toString().slice(1) + (date.getDate() + 100).toString().slice(1), 10);
};

export const mysqlTimeStampToDataTime = (timestamp) => {
	//2022-09-10 21:26:50
	const date = getDateObjectFromMySqlDate(timestamp);
	return `${formatDate(date)}, ${formatTime(date)}`;
};

export const niceCount = (count, one, many, zero = null) => {
	if (count === 0) {
		return "0 " + (zero || many);
	}
	if (count === 1) {
		return "1 " + one;
	}
	return count.toString() + " " + many;
};

export const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const validateGLN = (gln) => {
	if (typeof gln !== "string") {
		return false;
	}
	if (!gln.match(/^760100\d{7}$/)) return false;

	let sum = 0;
	for (let i = 0; i < 12; i++) {
		const currentNumber = parseInt(gln.charAt(i), 10);
		if (i % 2 > 0) {
			sum += 3 * currentNumber;
		} else {
			sum += currentNumber;
		}
	}
	// sum = 43
	const lastNumber = parseInt(sum.toString().slice(-1), 10);
	const checkNumber = lastNumber === 0 ? "0" : (-lastNumber + 10).toString();
	return checkNumber === gln.slice(-1);
};
