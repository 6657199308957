import { ReactComponent as Favorite } from "../../../../../../assets/images/icon_favorite.svg";
import ColorIcon from "../../../../../ColorIcon/ColorIcon";
import Points from "../../../../../Display/Points/Points";
import styles from "./ShowBooked.module.css";

const ShowBooked = ({ isFavorite, startDay, endDay, serviceTemplate, chance, points, includesPublicHolidays }) => {
    const classNames = [styles.service];
    endDay !== null && classNames.push(styles.grouped);
    includesPublicHolidays && classNames.push(styles.publicHoliday);

    const getDayName = (day) => {
        return day.weekend ? <strong>{day.dayName}.</strong> : `${day.dayName}.`;
    };

    const getDate = (day) => {
        return day.weekend ? (
            <strong>
                {startDay.dayNr}. {startDay.monthName}
            </strong>
        ) : (
            `${startDay.dayNr}. ${startDay.monthName}`
        );
    };

    const isDifferentDay = () => {
        return endDay && (endDay.dayNr !== startDay.dayNr || endDay.monthName !== startDay.monthName);
    };

    return (
        <div className={classNames.join(" ")}>
            <div className={styles.favorite}>{isFavorite && <Favorite />}</div>
            <div className={styles.dayName}>
                {getDayName(startDay)}
                {isDifferentDay() && (
                    <>
                        <br />
                        {getDayName(endDay)}
                    </>
                )}
            </div>
            <div className={styles.date}>
                {getDate(startDay)}
                {isDifferentDay() && (
                    <>
                        {` –`}
                        <br />
                        {endDay.dayNr}. {endDay.monthName}
                    </>
                )}
            </div>
            <div className={styles.serviceTemplate}>
                {endDay ? (
                    <i>Gruppierter Dienst</i>
                ) : (
                    <>
                        <ColorIcon colorId={serviceTemplate ? serviceTemplate.colorId : "0"} /> {serviceTemplate ? serviceTemplate.name : "???"}
                    </>
                )}
            </div>
            <div className={styles.points}>
                <Points>{points}</Points>
            </div>
        </div>
    );
};

export default ShowBooked;
