import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatDate, getDateObjectFromMySqlDate, mysqlTimeStampToDataTime, niceCount } from "../../../shared/functions";
import { getRosterTabs, serviceTemplateTypes } from "../../../shared/globals";

import ShowUser from "../../../components/Display/ShowUser/ShowUser";

import styles from "./RosterState.module.css";
import ServiceTemplates from "../../../components/Roster/RosterState/ServiceTemplates/ServiceTemplates";
import EditFavoritesPeriod from "../../../components/Roster/RosterState/EditFavoritesPeriod/EditFavoritesPeriod";
import Step from "../../../components/Roster/RosterState/Step/Step";

import * as routes from "../../../shared/routes";
import { getPossibleRosterDoctors, getRosterInfo, getRosterState, getRosterSubscribers } from "../../../features/roster/rosterSlice";
import ServiceCircle from "../../../components/Display/ServiceCircle/ServiceCircle";
import ServiceType from "../../../components/Display/ServiceType/ServiceType";
import Panel from "../../../hoc/Panel/Panel";

const RosterState = () => {
    const rosterInfo = useSelector((state) => state.roster.info);
    const rosterState = useSelector((state) => state.roster.state);
    const { serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);
    const [editFavoritesPeriod, setEditFavoritesPeriod] = useState(false);
    const subscribers = useSelector((state) => state.roster.subscribers);
    const doctors = useSelector((state) => state.roster.doctors);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRosterInfo(rosterPeriodId));
        dispatch(getRosterSubscribers(rosterPeriodId));
        dispatch(getPossibleRosterDoctors(rosterPeriodId));
        dispatch(getRosterState(rosterPeriodId));
    }, [rosterPeriodId, dispatch]);

    let content = null;
    if (rosterInfo && subscribers && doctors && rosterState) {
        const serviceTemplatesBookable = rosterInfo.serviceTemplates.filter((entry) => entry.type === serviceTemplateTypes.bookable);
        const serviceTemplatesFix = rosterInfo.serviceTemplates.filter((entry) => entry.type === serviceTemplateTypes.fix);

        const countServicesInRoster = serviceTemplatesBookable.reduce((count, template) => (count += template.count), 0);
        //const subscribersOk = subscribers.length > 0 && subscribers.reduce((countErrors, subscriber) => doctors.find())
        content = (
            <div className={styles.content}>
                <div>
                    <h3>Prozess</h3>
                    <Step title="Geographie" goTo={routes.GEOGRAPHY} ok={rosterState.planning.hasAllBaseMapsInDateRange}>
                        {rosterState.planning.hasAllBaseMapsInDateRange ? (
                            <Fragment>
                                Jedem einzelnen Tag der Dienstart «<ServiceType />» wurde eine Basiskarte zugeordnet.
                            </Fragment>
                        ) : (
                            <Fragment>
                                Ordne jedem einzelnen Tag der Dienstart «<ServiceType />» eine Basiskarte zu.
                            </Fragment>
                        )}
                    </Step>
                    <Step title="Dienstplan | Dienste" goTo={routes.ROSTER + "/" + routes.ROSTER_TIMES} ok={countServicesInRoster > 0}>
                        {countServicesInRoster > 0 ? "Es wurden Dienste definiert und im Kalender eingetragen." : serviceTemplatesBookable.length > 0 ? "Nehme Dienste im Kalender auf" : "Definiere Dienste und nehme sie in den Kalender auf."}
                    </Step>
                    <Step title="Dienstplan | Ärzte" goTo={routes.ROSTER + "/" + routes.ROSTER_DOCTORS} ok={rosterState.planning.doctorsOk}>
                        {rosterState.planning.doctorsOk ? "Dem Dienstplan wurden Ärzte zugewiesen." : <Fragment>{subscribers.length > 0 ? "Prüfe vorhandene Fehler bei den zugewiesenen Ärzten." : "Weise dem Dienstplan Ärzte zu."}</Fragment>}
                    </Step>
                    <Step title="Struktur | Absenzkontingent" goTo={routes.STRUCTURE + "/" + serviceCircleId + routes.STRUCTURE_CONTINGENT} ok={rosterState.planning.hasContingents}>
                        {rosterState.planning.hasContingents ? (
                            <Fragment>
                                Es wurden Absenzkontingente für den Dienstkreis «<ServiceCircle />» erfasst.
                            </Fragment>
                        ) : (
                            <Fragment>
                                Erfasse Absenzkontingente für den Dienstkreis «<ServiceCircle />
                                ».
                            </Fragment>
                        )}
                    </Step>
                    <Step title="Favoritenperiode" edit={() => setEditFavoritesPeriod(true)} ok={rosterInfo.favoriteStart !== null}>
                        {rosterInfo.favoriteStart !== null ? (
                            <Fragment>
                                Die Periode für die Dienstwünsche wurde definiert.
                                <br />
                                {mysqlTimeStampToDataTime(rosterInfo.favoriteStart)} - {mysqlTimeStampToDataTime(rosterInfo.favoriteEnd)}
                            </Fragment>
                        ) : (
                            "Definiere die Periode, in welcher die Ärzte ihre Dienstwünsche eintragen können."
                        )}
                    </Step>
                </div>
                <div>
                    <h3>Informationen und Statistik</h3>
                    <fieldset className={styles.fieldset}>
                        <legend>Dienstplan</legend>
                        <ul className={styles.list}>
                            <li>
                                <label>Erstellt</label>
                                <div>
                                    {mysqlTimeStampToDataTime(rosterInfo.createdOn)}, <ShowUser id={rosterInfo.createdFrom} />
                                </div>
                            </li>
                            <li>
                                <label>Zeitraum</label>
                                <div>
                                    {formatDate(getDateObjectFromMySqlDate(rosterInfo.start))} – {formatDate(getDateObjectFromMySqlDate(rosterInfo.end))}
                                </div>
                            </li>
                            <li>
                                <label>Notfalldienst</label>
                                <div>{rosterInfo.serviceAt}</div>
                            </li>
                            <li>
                                <label>Tage</label>
                                <div className={styles.countDays}>
                                    <div>{rosterInfo.days}</div>
                                    <ul>
                                        <li>{niceCount(rosterInfo.weekDays, "Werktag", "Werktage")}</li>
                                        <li>{niceCount(rosterInfo.weekendDays, "Wochenendtag", "Wochenendtage")}</li>
                                        <li>{niceCount(rosterInfo.globalPublicHolidays, "globaler Feiertag", "globale Feiertage")}</li>
                                        <li>{niceCount(rosterInfo.localPublicHolidays, "lokaler Feiertag", "lokale Feiertage")}</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <label>Ärzte</label>
                                <div>
                                    {subscribers.length} (
                                    {rosterInfo.totalAmounts.minAmount === rosterInfo.totalAmounts.maxAmount ? (
                                        rosterInfo.totalAmounts.amount.toLocaleString("de-CH") + "%"
                                    ) : (
                                        <>
                                            <span className={styles.small}>{rosterInfo.totalAmounts.minAmount.toLocaleString("de-CH")}%</span>...<strong>{rosterInfo.totalAmounts.amount.toLocaleString("de-CH")}%</strong>...
                                            <span className={styles.small}>{rosterInfo.totalAmounts.maxAmount.toLocaleString("de-CH")}%</span>
                                        </>
                                    )}
                                    )
                                </div>
                            </li>
                            <li>
                                <label>Punkte</label>
                                <div>{rosterInfo.pointsToReach}</div>
                            </li>
                        </ul>
                    </fieldset>
                    <fieldset className={styles.fieldset}>
                        <legend>Dienste</legend>
                        {serviceTemplatesBookable.length > 0 ? (
                            <ServiceTemplates serviceTemplates={serviceTemplatesBookable} />
                        ) : (
                            <div>
                                <i>Keine</i>
                            </div>
                        )}
                    </fieldset>
                    {rosterInfo.serviceAlwaysInMedicalPracticeId !== "0" && (
                        <fieldset className={styles.fieldset}>
                            <legend>Fix-Dienste</legend>
                            {serviceTemplatesFix.length > 0 ? (
                                <ServiceTemplates serviceTemplates={serviceTemplatesFix} />
                            ) : (
                                <div>
                                    <i>Keine</i>
                                </div>
                            )}
                        </fieldset>
                    )}
                </div>
                {editFavoritesPeriod && <EditFavoritesPeriod cancel={() => setEditFavoritesPeriod(false)} rosterPeriodId={rosterPeriodId} favoriteStart={rosterInfo.favoriteStart} favoriteEnd={rosterInfo.favoriteEnd} />}
            </div>
        );
    }

    return (
        <Panel title="Dienstplan | Status" size="xLarge" rosterPeriode categorieLevels={4} tabs={getRosterTabs(rosterState)} padding>
            {content}
        </Panel>
    );
};

export default RosterState;
