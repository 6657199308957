import ColorIcon from "../../../../../ColorIcon/ColorIcon";
import Points from "../../../../../Display/Points/Points";
import ProgressBar from "../../../../../Display/ProgressBar/ProgressBar";
import styles from "./ShowFavorite.module.css";

const ShowFavorite = ({ isBooked, startDay, endDay, serviceTemplate, chance, points, includesPublicHolidays }) => {
	const classNames = [styles.service];
	endDay !== null && classNames.push(styles.grouped);
	includesPublicHolidays && classNames.push(styles.publicHoliday);

	const getDayName = (day) => {
		return day.weekend ? <strong>{day.dayName}.</strong> : `${day.dayName}.`;
	};

	const isDifferentDay = () => {
		return endDay && (endDay.dayNr !== startDay.dayNr || endDay.monthName !== startDay.monthName);
	};

	return (
		<div className={classNames.join(" ")}>
			<div className={styles.booked}>{isBooked && "✔"}</div>
			<div className={styles.dayName}>
				{getDayName(startDay)}
				{isDifferentDay() && (
					<>
						<br /> - {getDayName(endDay)}
					</>
				)}
			</div>
			<div className={styles.date}>
				{startDay.dayNr}. {startDay.monthName}
				{isDifferentDay() && (
					<>
						<br /> - {endDay.dayNr}. {endDay.monthName}
					</>
				)}
			</div>
			<div className={styles.serviceTemplate}>
				{endDay ? (
					<i>Gruppierter Dienst</i>
				) : (
					<>
						<ColorIcon colorId={serviceTemplate ? serviceTemplate.colorId : "0"} /> {serviceTemplate ? serviceTemplate.name : "???"}
					</>
				)}
			</div>
			<div>
				<ProgressBar value={chance} width="60px" title={`${Math.round(chance)}%`} />
			</div>
			<div className={styles.points}>
				<Points>{points}</Points>
			</div>
		</div>
	);
};

export default ShowFavorite;
