import { Fragment } from "react";
import { formatDate, getDateObjectFromMySqlDate, getNfdLoadFromWorkLoad } from "../../../shared/functions";
import ResourceList from "../../../ui/List/ResourceList/ResourceList";
import { nfdStates, phoneTypes } from "../../../shared/globals";
import Phone from "../../../ui/Phone/Phone";
import EmailAddress from "../../../ui/EmailAddress/EmailAddress";
import ResourceRow from "../../../ui/ResourceRow/ResourceRow";

const Person = ({ person }) => {
	const phoneEntries = person.phones.map((phone) => ({
		id: phone.id,
		name: (
			<ResourceRow label={phone.label || `${phoneTypes[phone.type]} Praxis`} labelColor={phone.labelColor} icon={phone.type} secret={phone.secret}>
				<Phone phone={phone} />
			</ResourceRow>
		)
	}));

	const eMailEntries = person.eMails.map((eMail) => ({
		id: eMail.id,
		name: (
			<ResourceRow label={eMail.label || "Praxis"} labelColor={eMail.labelColor} icon="eMail" auth={eMail.auth}>
				<EmailAddress eMail={eMail.eMail} />
			</ResourceRow>
		)
	}));

	return (
		<Fragment>
			<div>
				<label>
					<span>Arzt</span>
					{person.title} {person.firstname} {person.lastname}
				</label>
				<label>
					<span>Geburtsdatum</span>
					{person.birthdate ? formatDate(getDateObjectFromMySqlDate(person.birthdate)) : "-"}
				</label>
				<label>
					<span>GLN</span>
					{person.gln}
				</label>
				<label>
					<span>Notfalldienst-Status</span>
					<div>
						{person.nfdState ? nfdStates.find((entry) => entry.key === person.nfdState).name : "-"}
						{person.nfdState === "inactive" && `, ${person.nfdStateDetails}`}
					</div>
				</label>
				<label>
					<span>Arbeitspensum</span>
					{person.workload}% {person.nfdState === "active_service" && <>(Notfalldienstpensum: {getNfdLoadFromWorkLoad(person.workload)}%)</>}
				</label>
			</div>
			<div>
				<label>
					<span>Telefon & Fax</span>
					<ResourceList entries={phoneEntries} alternating={false} />
				</label>
				<label>
					<span>E-Mail Adressen</span>
					<ResourceList entries={eMailEntries} alternating={false} />
				</label>
				<label>
					<span>Spezialgebiete</span>
					<ul>
						{person.areasOfExpertise.map((areaOfExpertise) => (
							<li key={areaOfExpertise.id}>{areaOfExpertise.name}</li>
						))}
					</ul>
				</label>
			</div>
		</Fragment>
	);
};

export default Person;
