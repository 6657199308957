import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { showCategorySelector } from "../../../features/category/categorySlice";
import { getRosterState } from "../../../features/roster/rosterSlice";

const RosterPageSelector = () => {
    const dispatch = useDispatch();
    const { rosterPeriodId } = useSelector((state) => state.category.selected);
    const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
    const rosterPeriode = rosterPeriodId ? rosterPeriodes.find((rosterPeriod) => rosterPeriod.id === rosterPeriodId) : null;
    const rosterState = useSelector((state) => state.roster.state);

    useEffect(() => {
        if (!rosterPeriodId) {
            dispatch(showCategorySelector({ requiredLevel: 4 }));
        }
    }, [dispatch, rosterPeriodId]);

    useEffect(() => {
        if (rosterPeriode && !rosterState) {
            dispatch(getRosterState(rosterPeriodId));
        }
    }, [rosterPeriode, rosterState, rosterPeriodId, dispatch]);

    // useEffect(() => {
    //     if (rosterPeriodId && rosterState && rosterState.id !== rosterPeriodId) {
    //         dispatch(getRosterState(rosterPeriodId));
    //     }
    // }, [rosterPeriodId, rosterState, dispatch]);

    return rosterPeriodId > 0 ? <Outlet /> : null;
};

export default RosterPageSelector;
