import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Map from "./Map/Map";
import TimeSelector from "./TimeSelector/TimeSelector";
import ServiceCircleEditor from "../ServiceCircleEditor/ServiceCircleEditor";
import ServiceCircleSelector from "../../ServiceCircleSelector/ServiceCircleSelector";
import Note from "../../Note/Note";

import { getRegions } from "../../../features/region/regionSlice";
import Panel from "../../../hoc/Panel/Panel";
import { displayTimeRange } from "../../../shared/functions";
import Spinner from "../../../ui/Spinner";
import Window from "../../../hoc/Window/Window";

import styles from "./EditPlan.module.css";
import PlanName from "./PlanName/PlanName";
import { createGeographyPlan, updateGeographyPlan, copy, paste, updateGeographyPlanName, addZipsToServiceCircleTime, removeZipsFromServiceCircleTime } from "../../../features/geographyPlan/geographyPlanSlice";
import ActionTools from "../../ActionTools/ActionTools";

import * as askTypes from "../../../shared/askTypes";
import { ask, clear } from "../../../features/ask/askSlice";

const EditPlan = ({ cancel }) => {
	const regions = useSelector((state) => state.regions);
	const selectedTimeId = useSelector((state) => state.geographyPlan.selectedTimeId);
	const plan = useSelector((state) => state.geographyPlan.plan);
	const clipboard = useSelector((state) => state.geographyPlan.clipboard);
	const serviceCircles = useSelector((state) => state.category.serviceCircles);
	const answer = useSelector((state) => state.ask.answer);
	const { serviceTypeId, serviceCircleId } = useSelector((state) => state.category.selected);
	const currentServiceCircles = useMemo(() => (serviceTypeId ? serviceCircles.filter((entry) => entry.serviceTypeId === serviceTypeId) : []), [serviceTypeId, serviceCircles]);

	const [mouseOverServiceCircle, setMouseOverServiceCircle] = useState(0);
	const [name, setName] = useState(plan.name);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (!regions) dispatch(getRegions());
	}, [dispatch, regions]);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.PASTE_ZIPS && answer.value) {
				dispatch(paste());
				dispatch(clear());
			}
		}
	}, [answer, dispatch]);

	if (!regions) {
		return null;
	}

	const currentZips = selectedTimeId !== null ? plan.times.find((time) => time.id === selectedTimeId).zips : [];

	const over = (id, isOver = true) => {
		setMouseOverServiceCircle(isOver ? id : 0);
	};

	const save = () => {
		if (plan.id) {
			if (plan.locked) {
				dispatch(updateGeographyPlanName({ id: plan.id, data: { name }, success: `Die zeitabhängige Karte «${name}» wurde erfolgreich gespeichert.` }));
			} else {
				dispatch(updateGeographyPlan({ id: plan.id, data: { ...plan, name }, success: `Die zeitabhängige Karte «${name}» wurde erfolgreich gespeichert.` }));
			}
		} else {
			dispatch(createGeographyPlan({ serviceTypeId, data: { ...plan, name }, success: `Die zeitabhängige Karte «${name}» wurde erfolgreich erstellt.` }));
		}
	};

	const copyZips = () => {
		const time = plan.times.find((entry) => entry.id === selectedTimeId);
		dispatch(copy({ name: `${plan.name}, ${displayTimeRange(time.start, time.end)}`, zips: [...currentZips] }));
	};

	const pasteZips = () => {
		if (currentZips.length > 0) {
			dispatch(
				ask({
					type: askTypes.PASTE_ZIPS,
					title: "Karte einfügen",
					question: "Möchtest Du die Karte «" + clipboard.name + "» wirklich einfügen? Damit werden alle vorhandenen Regionen ersetzt.",
					buttons: [
						{ label: "Abbrechen", answer: null },
						{ label: "Ja, einfügen", answer: true }
					]
				})
			);
		} else {
			dispatch(paste());
		}
	};

	const addZips = (regions) => {
		dispatch(addZipsToServiceCircleTime({ serviceCircleId, timeId: selectedTimeId, regions }));
	};

	const removeZips = (regions) => {
		dispatch(removeZipsFromServiceCircleTime({ serviceCircleId, timeId: selectedTimeId, regions }));
	};

	const actionToolsMenuItems = [];
	if (currentZips.length > 0) {
		actionToolsMenuItems.push({ name: "Karte kopieren", action: () => copyZips() });
	}
	if (clipboard) {
		actionToolsMenuItems.push({ name: `Karte «${clipboard.name}» einfügen`, action: () => pasteZips(), disabled: plan.locked });
	}

	return (
		<Window>
			<Panel size="full" title="Zeitabhängige Karte bearbeiten" empty padding>
				<div className={styles.map}>
					<div className={styles.mapPanel}>{isLoaded ? <Map serviceCircles={currentServiceCircles} mouseOverServiceCircle={mouseOverServiceCircle} locked={plan.locked} /> : <Spinner />}</div>
					<div className={styles.sidePanel}>
						<div className={styles.sidePanelTop}>
							<PlanName name={name} setName={setName} />
							{plan.locked && <Note marginTop>Diese zeitabhängige Karte kann nicht mehr verändert werden, da sie in der Vergangenheit verwendet wurde.</Note>}
						</div>
						<div className={styles.mainSidePanel}>
							<div className={styles.serviceCircle}>
								{selectedTimeId && <ActionTools menuItems={actionToolsMenuItems} dark />}
								{selectedTimeId !== null ? <ServiceCircleSelector serviceCircles={currentServiceCircles} serviceCircleId={serviceCircleId} over={over} /> : <Note>Bitte wähle eine Zeit</Note>}
								{selectedTimeId !== null && serviceCircleId > 0 && <ServiceCircleEditor serviceCircleId={serviceCircleId} zips={currentZips} locked={plan.locked} addZipsToServiceCircle={addZips} removeZipsFromServiceCircle={removeZips} />}
							</div>
							<div className={styles.timePanel}>
								<TimeSelector planId={plan.id} locked={plan.locked} />
							</div>
						</div>
						<div className={styles.buttons}>
							<button type="button" onClick={cancel}>
								Abbrechen
							</button>
							<button type="button" onClick={save} disabled={name.trim().length < 2}>
								Speichern
							</button>
						</div>
					</div>
				</div>
			</Panel>
		</Window>
	);
};

export default EditPlan;
