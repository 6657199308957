import { displayTimeRange, endTimeToPercent, startTimeToPercent } from "../../../../../../shared/functions";
import styles from "./TimeBar.module.css";

const TimeBar = ({ time, dateInt }) => {
    let showTitle = true;

    const style = {
        backgroundColor: time.color,
        color: time.whiteText ? "white" : "#535557"
    };
    if (time.start > time.end) {
        if (time.startDate === dateInt) {
            style.left = startTimeToPercent(time.start) + "%";
            style.right = "0%";
        } else {
            style.left = "0%";
            style.right = endTimeToPercent(time.end) + "%";
            showTitle = false;
        }
    } else {
        style.left = startTimeToPercent(time.start) + "%";
        style.right = endTimeToPercent(time.end) + "%";
    }
    const title = displayTimeRange(time.start, time.end, false);

    return (
        <div className={styles.time} style={style} title={title}>
            {showTitle && title}
        </div>
    );
};

export default TimeBar;
