import { displayTimeRange } from "../../../../shared/functions";
import ColorIcon from "../../../ColorIcon/ColorIcon";
import styles from "./ServiceTemplates.module.css";
import ServiceTemplateRow from "./ServiceTemplateRow/ServiceTemplateRow";

const ServiceTemplates = ({ serviceTemplates }) => {
	return (
		<table className={styles.table}>
			<thead>
				<ServiceTemplateRow name="Name" time="Dienstzeit" count="Anzahl" header />
			</thead>
			<tbody>
				{serviceTemplates.map((entry) => (
					<ServiceTemplateRow key={entry.id} icon={<ColorIcon colorId={entry.colorId} />} name={entry.name} time={displayTimeRange(entry.start, entry.end, true)} count={entry.count} />
				))}
			</tbody>
		</table>
	);
};

export default ServiceTemplates;
