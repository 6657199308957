import * as routes from "./routes";

export const colors = [
    { background: "#F6F9A4", whiteText: false },
    { background: "#D8F9A4", whiteText: false },
    { background: "#A4F9B8", whiteText: false },
    { background: "#A4F1F9", whiteText: false },
    { background: "#BEC2F6", whiteText: false },
    { background: "#EAB0EE", whiteText: false },
    { background: "#DDC5D3", whiteText: false },
    { background: "#F9D9A4", whiteText: false },
    { background: "#7B7E10", whiteText: true },
    { background: "#59A009", whiteText: true },
    { background: "#09A096", whiteText: true },
    { background: "#12688C", whiteText: true },
    { background: "#463090", whiteText: true },
    { background: "#A00973", whiteText: true },
    { background: "#A00909", whiteText: true },
    { background: "#844F08", whiteText: true }
];

export const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
export const dayNames = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
export const longDayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
export const serviceTemplateTypes = {
    bookable: "bookable",
    fix: "fix"
};

export const userTypes = {
    doctor: "Arzt",
    mpa: "MPA"
};

export const nfdStates = [
    {
        key: "active_service",
        name: "Pflichtig, im Einsatz"
    },
    {
        key: "active_pay",
        name: "Pflichtig, bezahlt Ersatzabgabe"
    },
    {
        key: "inactive",
        name: "Nicht pflichtig"
    }
];

export const phoneTypes = {
    mobile: "Mobile",
    phone: "Festnetz",
    fax: "Fax"
};

export const labelColors = {
    green: {
        backgroundColor: "#b6d767"
    },
    turquoise: {
        backgroundColor: "#57d2da"
    },
    violet: {
        backgroundColor: "#b1aaeb"
    },
    gold: {
        backgroundColor: "#dac257"
    },
    pink: {
        backgroundColor: "#f0a8d9"
    }
};

export const getRosterTabs = (rosterState) => {
    const tabs = [
        {
            location: routes.ROSTER + "/" + routes.ROSTER_STATE,
            name: "Status"
        },
        {
            location: routes.ROSTER + "/" + routes.ROSTER_TIMES,
            name: "Dienste"
        },
        {
            location: routes.ROSTER + "/" + routes.ROSTER_DOCTORS,
            name: "Ärzte"
        }
    ];
    if (rosterState && (rosterState.state === "favorites" || rosterState.state === "waitForMagic")) {
        tabs.push({
            location: routes.ROSTER + "/" + routes.ROSTER_ALLOCATION,
            name: "Zuteilung",
            subPages: [
                {
                    location: routes.ROSTER + "/" + routes.ROSTER_ALLOCATION + "/" + routes.ROSTER_ALLOCATION_TABLE,
                    name: "Tabellenansicht"
                },
                {
                    location: routes.ROSTER + "/" + routes.ROSTER_ALLOCATION + "/" + routes.ROSTER_ALLOCATION_CALENDAR,
                    name: "Kalenderansicht"
                }
            ]
        });
    }
    return tabs;
};
