import styles from "./Note.module.css";

const Note = ({ children, marginTop = false, marginBottom = false }) => {
	const classNames = [styles.note];
	marginTop && classNames.push(styles.marginTop);
	marginBottom && classNames.push(styles.marginBottom);
	return <div className={classNames.join(" ")}>{children}</div>;
};

export default Note;
