import React, { useRef, useState } from "react";

import styles from "./InputTime.module.css";

const percentToMinute = (percent) => {
	return 60 * percent;
};

const minuteToPercent = (minute) => {
	return (1 / 60) * parseInt(minute);
};

const InputTime = ({ time = null, set, error = false, disabled = false }) => {
	// time ---> 8.25 ---> 8h 15Min

	const hour = time ? Math.trunc(time) : "";
	const minute = time ? time - hour : "";

	const [editHour, setEditHour] = useState(`${hour}`);
	const [editMinute, setEditMinute] = useState(`${(percentToMinute(minute) + 100).toString().slice(1, 3)}`);
	const minuteRef = useRef();

	const classNames = [styles.time];
	error && classNames.push(styles.error);

	const changeHour = (e) => {
		const value = e.target.value;

		if (value === "" || (value.match(/^(\d){1,2}$/) && parseInt(value) < 24)) {
			setEditHour(value);
			set(parseInt(value) + minuteToPercent(editMinute));
			if (value.length === 2) {
				minuteRef.current.focus();
			}
		}
	};

	const changeMinute = (e) => {
		const value = e.target.value;

		if (value === "" || (value.match(/^(\d){1,2}$/) && parseInt(value) < 60)) {
			setEditMinute(value);
			set(parseInt(editHour) + minuteToPercent(value || 0));
		}
	};

	const selectAll = (e) => {
		e.target.select();
	};

	return (
		<div className={classNames.join(" ")}>
			<input type="text" value={editHour} maxLength={2} onChange={changeHour} placeholder="hh" disabled={disabled} />:<input type="text" value={editMinute} ref={minuteRef} maxLength={2} onFocus={selectAll} onChange={changeMinute} placeholder="mm" disabled={disabled} />
		</div>
	);
};

export default InputTime;
