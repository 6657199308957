import { useState } from "react";
import { useDispatch } from "react-redux";

import { login } from "../../features/auth/authSlice";

import Panel from "../../hoc/Panel/Panel";
import Error from "../../ui/Error";

const Login = () => {
	const [eMail, setEMail] = useState("");
	const [password, setPassword] = useState("");

	const dispatch = useDispatch();

	const changeEMail = (event) => {
		setEMail(event.target.value);
	};

	const changePasswort = (event) => {
		setPassword(event.target.value);
	};

	const handleLogin = (event) => {
		event.preventDefault();
		dispatch(login({ eMail, password }));
	};
	return (
		<Panel title="Anmelden" size="small" padding>
			<Error />
			<form className="login panelBottom" onSubmit={handleLogin}>
				<label>
					<span>E-Mail</span>
					<input type="text" autoComplete="username" value={eMail} onChange={changeEMail} />
				</label>
				<label>
					<span>Passwort</span>
					<input type="password" autoComplete="current-password" value={password} onChange={changePasswort} />
				</label>
				<p>
					<button type="submit">Anmelden</button>
				</p>
			</form>
		</Panel>
	);
};

export default Login;
