import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import Aerztefon from "../assets/images/Aerztefon.png";
import { logout } from "../features/auth/authSlice";

const AerztefonLogo = () => {
    const authenticated = useSelector((state) => state.auth.authenticated);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);

    const version = "0.5.2";

    return (
        <div id="aerztefonLogo">
            <img src={Aerztefon} alt="Aerztefon" />
            <span>
                {authenticated && userData.name && (
                    <Fragment>
                        {userData.name}{" "}
                        <span className="click" onClick={() => dispatch(logout())}>
                            abmelden
                        </span>{" "}
                        •{" "}
                    </Fragment>
                )}
                V{version}
            </span>
        </div>
    );
};

export default AerztefonLogo;
