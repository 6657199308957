import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./EditGroupPanel.module.css";
import SmallPanel from "../../../../hoc/SmallPanel/SmallPanel";
import Note from "../../../Note/Note";
import { displayTimeRange, formatDate, getDateObjectFromMySqlDate } from "../../../../shared/functions";
import ColorRect from "../../../../hoc/ColorRect/ColorRect";
import { setGroup } from "../../../../features/roster/rosterSlice";
import { clearCloseOnExit } from "../../../../features/message/messageSlice";

const EditGroupPanel = ({ cancel, group }) => {
	const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
	const rosterPeriodId = useSelector((state) => state.roster.rosterPeriodId);
	const closeOnExit = useSelector((state) => state.message.closeOnExit);
	const dispatch = useDispatch();

	useEffect(() => {
		if (closeOnExit) {
			dispatch(clearCloseOnExit());
			cancel();
		}
	}, [closeOnExit, dispatch, cancel]);

	const services = group.entries
		.sort((a, b) => a.startTimeStamp - b.startTimeStamp)
		.map((service) => {
			const template = serviceTemplates.find((entry) => entry.id === service.serviceTemplateId);
			return { ...service, template };
		});

	const save = () => {
		const data = {
			ids: group.entries.map((entry) => entry.id)
		};
		dispatch(setGroup({ rosterPeriodId, groupId: group.groupId, data }));
	};

	return (
		<SmallPanel title="Dienste gruppieren">
			<div className={styles.content}>
				<Note marginBottom>Klicke im Kalender auf die Dienste, welche Du der Gruppe hinzufügen oder von der Gruppe entfernen möchtest.</Note>
				<ul className={styles.list}>
					{services.map((service) => (
						<li key={service.id}>
							<div>
								{formatDate(getDateObjectFromMySqlDate(service.date))}
								<br />
								<ColorRect colorId={service.template.colorId} className={styles.colorRect}>
									{displayTimeRange(service.template.start, service.template.end)}
								</ColorRect>
							</div>
						</li>
					))}
				</ul>
				<div className={styles.buttons}>
					<button type="button" onClick={() => cancel()}>
						Abbrechen
					</button>
					<button type="button" disabled={group.length < 2} onClick={save}>
						OK
					</button>
				</div>
			</div>
		</SmallPanel>
	);
};

export default EditGroupPanel;
