import { useSelector } from "react-redux";

import styles from "./DoctorFavorites.module.css";
import Points from "../../../../Display/Points/Points";
import ShowFavorite from "./ShowFavorite/ShowFavorite";
import { Fragment } from "react";

const DoctorFavorites = ({ favorites, booked, points }) => {
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);

    if (!serviceTemplates) {
        return null;
    }

    if (favorites.length === 0) {
        return <i>Keine</i>;
    }

    const totalPoints = favorites.reduce((points, entry) => (points += entry.points), 0);

    return (
        <Fragment>
            <div className={styles.header}>
                <Points>{totalPoints}</Points>
            </div>
            <div className={styles.favorites}>
                {favorites.map((entry) => {
                    const serviceTemplate = serviceTemplates.find((serviceTemplate) => serviceTemplate.id === entry.serviceTemplateId);
                    const isBooked = booked.find((service) => service.id === entry.id);
                    return <ShowFavorite key={entry.id} isBooked={isBooked} startDay={entry.start} endDay={entry.end} serviceTemplate={serviceTemplate} points={entry.points} chance={entry.chance} includesPublicHolidays={entry.includesPublicHolidays} />;
                })}
            </div>
        </Fragment>
    );
};

export default DoctorFavorites;
