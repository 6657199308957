import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { getDateObjectFromMySqlDate } from "../../shared/functions";
import { logout } from "../auth/authSlice";
import { setCloseOnExit, setTempError, setTempSuccess } from "../message/messageSlice";

const namespace = "roster";

const initialState = {
    rosterPeriodId: null,
    days: null,
    state: null,
    info: null,
    doctors: null,
    subscribers: null,
    rosterSubscriptionId: null,
    personId: null,
    doctorInputs: [],
    bookingsChecksum: null
};

const prepareRosterDays = (days) => {
    return days.map((entry) => {
        entry.start = getDateObjectFromMySqlDate(entry.date).valueOf();
        return entry;
    });
};

export const getRoster = createAsyncThunk(`${namespace}/getRoster`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        dispatch(prepare(payload));
        const { data } = await axios.get("roster/" + payload);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const addService = createAsyncThunk(`${namespace}/addService`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("roster/" + payload.rosterPeriodId, payload.data);
        return data;
    } catch (error) {
        dispatch(setTempError("Der Dienst konnte nicht eingefügt werden."));
        return rejectWithValue(error.response.status);
    }
});

export const addServiceRepetitive = createAsyncThunk(`${namespace}/addServiceRepetitive`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("roster/" + payload.rosterPeriodId + "/repetitive", payload.data);
        return data;
    } catch (error) {
        dispatch(setTempError("Die Dienst konnten nicht eingefügt werden."));
        return rejectWithValue(error.response.status);
    }
});

export const deleteService = createAsyncThunk(`${namespace}/deleteService`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.delete("roster/service/" + payload);
        return data;
    } catch (error) {
        dispatch(setTempError("Der Dienst konnte nicht gelöscht werden."));
        return rejectWithValue(error.response.status);
    }
});

export const setGroup = createAsyncThunk(`${namespace}/setGroup`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("roster/" + payload.rosterPeriodId + "/group/" + payload.groupId, payload.data);
        dispatch(setCloseOnExit());
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const resolveGroup = createAsyncThunk(`${namespace}/resolveGroup`, async (payload, { _, rejectWithValue }) => {
    try {
        const { data } = await axios.delete("roster/" + payload.rosterPeriodId + "/group/" + payload.id);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const getRosterState = createAsyncThunk(`${namespace}/getRosterState`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("roster/" + payload + "/state");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const getRosterInfo = createAsyncThunk(`${namespace}/getRosterInfo`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("roster/" + payload + "/info");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const getRosterSubscribers = createAsyncThunk(`${namespace}/getRosterSubscribers`, async (payload, { _, rejectWithValue }) => {
    try {
        const { data } = await axios.get("roster/" + payload + "/subscribers");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const getPossibleRosterDoctors = createAsyncThunk(`${namespace}/getPossibleRosterDoctors`, async (payload, { _, rejectWithValue }) => {
    try {
        const { data } = await axios.get("roster/" + payload + "/doctors");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const subscribeToRoster = createAsyncThunk(`${namespace}/subscribeToRoster`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("roster/" + payload.rosterPeriodId + "/" + payload.personId, payload.data);
        dispatch(setTempSuccess("Der Arzt wurde erfolgreich dem Dienstplan hinzugefügt."));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const updateRosterSubscribtion = createAsyncThunk(`${namespace}/updateRosterSubscribtion`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("roster/" + payload.rosterPeriodId + "/" + payload.subscriptionId, payload.data);
        dispatch(setTempSuccess("Die Einstellungen zum Dienst wurden erfolgreich gespeichert."));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const unsubscribeFromRoster = createAsyncThunk(`${namespace}/unsubscribeFromRoster`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.delete("roster/" + payload.rosterPeriodId + "/" + payload.subscriptionId);
        dispatch(setTempSuccess("Der Arzt wurde erfolgreich aus dem Dienstplan entfernt."));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const favoritesPeriod = createAsyncThunk(`${namespace}/favoritesPeriod`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("roster/" + payload.rosterPeriodId + "/favoritesPeriod", payload.data);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const allocate = createAsyncThunk(`${namespace}/allocate`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("roster/" + payload + "/allocate");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const createRoster = createAsyncThunk(`${namespace}/createRoster`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("roster/" + payload.rosterPeriodId + "/createRoster", payload.data);
        return data;
    } catch (error) {
        dispatch(allocate(payload.rosterPeriodId));
        dispatch(setTempError("Nöd guet"));
        return rejectWithValue(error.response.status);
    }
});

const rosterSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        prepare: (state, action) => {
            state.rosterPeriodId = action.payload;
            state.days = null;
        },
        setRosterSubscriptionId: (state, action) => {
            state.rosterSubscriptionId = action.payload;
        },
        clearRosterSubscriptionId: (state, action) => {
            state.rosterSubscriptionId = null;
        },
        setPersonId: (state, action) => {
            state.personId = action.payload;
        },
        clearPersonId: (state, action) => {
            state.personId = null;
        },
        clearRosterState: (state, action) => {
            state.state = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRoster.rejected, (state, { payload }) => {
                state.days = [];
            })
            .addCase(addService.fulfilled, (state, { payload }) => {
                state.days = state.days.map((day) => {
                    if (day.date === payload.date) {
                        return { ...day, services: [...day.services, { ...payload.service }] };
                    } else {
                        return day;
                    }
                });
                state.state = null;
            })
            .addCase(addServiceRepetitive.fulfilled, (state, { payload }) => {
                state.days = prepareRosterDays(payload);
                state.state = null;
            })
            .addCase(deleteService.fulfilled, (state, { payload }) => {
                state.days = state.days.map((day) => {
                    return { ...day, services: day.services.filter((service) => service.id !== payload.id) };
                });
                state.state = null;
            })
            .addCase(getRosterState.fulfilled, (state, { payload }) => {
                state.state = payload;
            })
            .addCase(getRosterInfo.fulfilled, (state, { payload }) => {
                state.info = payload;
            })
            .addCase(favoritesPeriod.fulfilled, (state, { payload }) => {
                state.info = payload;
                state.state = null;
            })
            .addCase(getRosterSubscribers.fulfilled, (state, { payload }) => {
                state.subscribers = payload;
            })
            .addCase(updateRosterSubscribtion.fulfilled, (state, { payload }) => {
                state.subscribers = payload;
                state.rosterSubscriptionId = null;
                state.state = null;
            })
            .addCase(subscribeToRoster.fulfilled, (state, { payload }) => {
                state.subscribers = payload;
                state.personId = null;
                state.state = null;
            })
            .addCase(unsubscribeFromRoster.fulfilled, (state, { payload }) => {
                state.subscribers = payload;
                state.rosterSubscriptionId = null;
                state.state = null;
            })
            .addCase(getPossibleRosterDoctors.fulfilled, (state, { payload }) => {
                state.doctors = payload;
            })
            .addCase(allocate.fulfilled, (state, { payload }) => {
                state.doctorInputs = payload.persons;
                state.days = prepareRosterDays(payload.calendar);
                state.bookingsChecksum = payload.bookingsChecksum;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                return initialState;
            })
            .addMatcher(isAnyOf(getRoster.fulfilled, setGroup.fulfilled, resolveGroup.fulfilled), (state, { payload }) => {
                state.days = prepareRosterDays(payload);
            });
    }
});

export const { prepare, setRosterSubscriptionId, clearRosterSubscriptionId, setPersonId, clearPersonId, clearRosterState } = rosterSlice.actions;

export default rosterSlice.reducer;
