import { useSelector } from "react-redux";

import Calendar from "../../../../components/Roster/Allocation/AllocationCalendar/Calendar";
import MonthSelector from "../../../../components/Roster/MonthSelector/MonthSelector";
import Panel from "../../../../hoc/Panel/Panel";

import styles from "./AllocationCalendar.module.css";

import { getDateObjectFromMySqlDate } from "../../../../shared/functions";
import { getRosterTabs } from "../../../../shared/globals";

import Persons from "../../../../components/Roster/Allocation/AllocationCalendar/Persons/Persons";
import { useEffect, useState } from "react";
import BookNow from "../BookNow/BookNow";

const AllocationCalendar = () => {
    const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
    const { rosterPeriodId } = useSelector((state) => state.category.selected);
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
    const rosterPeriode = rosterPeriodId ? rosterPeriodes.find((entry) => entry.id === rosterPeriodId) : null;
    const rosterDays = useSelector((state) => state.roster.days);
    const publicHolidays = useSelector((state) => state.publicHoliday.roster);
    const marked = useSelector((state) => state.personInformation.marked);
    const rosterState = useSelector((state) => state.roster.state);
    const [countBookedServicesInMonth, setCountBookedServicesInMonth] = useState([]);

    useEffect(() => {
        const result = [];
        if (marked) {
            rosterDays.forEach((rosterDay) => {
                if (rosterDay.services.length > 0) {
                    const countBookings = rosterDay.services.filter((service) => service.bookedFrom === marked).length;
                    if (countBookings) {
                        const [year, month] = rosterDay.date.split("-");
                        const key = parseInt(month).toString() + "-" + year;
                        if (result[key]) {
                            result[key] += countBookings;
                        } else {
                            result[key] = countBookings;
                        }
                    }
                }
            });
        }
        setCountBookedServicesInMonth(result);
    }, [marked, rosterDays]);

    if (!rosterDays || !publicHolidays) {
        return null;
    }

    const rosterPeriodeStart = getDateObjectFromMySqlDate(rosterPeriode.start);
    const rosterPeriodeEnd = getDateObjectFromMySqlDate(rosterPeriode.end);
    const calendar = serviceTemplates && serviceTemplates.length > 0 && rosterDays ? <Calendar rosterDays={rosterDays} rosterPeriodeStart={rosterPeriodeStart} rosterPeriodeEnd={rosterPeriodeEnd} publicHolidays={publicHolidays} /> : null;

    return (
        <Panel title="Dienstplan | Buchungen" rosterPeriode categorieLevels={4} tabs={getRosterTabs(rosterState)} padding>
            <BookNow />
            <div className={styles.roster}>
                <div>
                    {rosterPeriode && (
                        <div className={styles.monthSelector}>
                            <MonthSelector start={rosterPeriodeStart} end={rosterPeriodeEnd} monthCounts={countBookedServicesInMonth} />
                        </div>
                    )}
                    {calendar}
                </div>
                <div className={styles.sidePanel}>
                    <Persons />
                </div>
            </div>
        </Panel>
    );
};

export default AllocationCalendar;
