import { Fragment } from "react";

//import styles from "./ServiceAvailability.module.css";

const ServiceAvailability = () => {
	return (
		<Fragment>
			<h2>Diensterreichbarkeit</h2>
		</Fragment>
	);
};

export default ServiceAvailability;
