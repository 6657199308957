import { Fragment, useState } from "react";
import Fieldset from "../../../../../hoc/Fieldset/Fieldset";
import FooterButtons from "../../../../../ui/FooterButtons/FooterButtons";

import styles from "./Address.module.css";
import { useDispatch } from "react-redux";
import { updateMedicalPractice } from "../../../../../features/medicalPractice/medicalPracticeSlice";
import { validateGLN } from "../../../../../shared/functions";

const Address = ({ medicalPractice: { id, name, gln, street, street2, zip, city } }) => {
    const [edit, setEdit] = useState(false);
    const initialAddress = {
        id,
        name,
        gln,
        street,
        street2,
        zip,
        city
    };
    const [address, setAddress] = useState({ ...initialAddress });
    const dispatch = useDispatch();

    const disabled = address.street.trim().length < 2 || address.zip.length !== 4 || address.city.trim().length < 2 || (address.gln !== "" && !validateGLN(address.gln));

    const change = (e) => {
        const fieldname = e.target.name;
        const value = e.target.value;

        if ((fieldname === "zip" || fieldname === "gln") && !value.match(/^\d*$/)) {
            return;
        }
        setAddress((prev) => ({ ...prev, [fieldname]: value }));
    };

    const cancelEdit = () => {
        setEdit(false);
        setAddress({ ...initialAddress });
    };

    const saveAddress = () => {
        dispatch(updateMedicalPractice({ id, data: { ...address } }));
        setEdit(false);
    };

    return (
        <Fieldset legend="Praxis">
            {edit ? (
                <Fragment>
                    <label className={styles.label}>
                        <span>Praxisname</span>
                        <input type="text" name="name" value={address.name} onChange={change} maxLength={128} />
                    </label>
                    <label className={styles.label}>
                        <span>Strasse</span>
                        <input type="text" name="street" value={address.street} onChange={change} maxLength={128} />
                    </label>
                    <label className={styles.label}>
                        <span>Strasse 2</span>
                        <input type="text" name="street2" value={address.street2} onChange={change} maxLength={128} />
                    </label>
                    <label className={styles.label}>
                        <span>PLZ</span>
                        <input type="text" name="zip" value={address.zip} onChange={change} maxLength={4} className={address.zip === "" || address.zip.length === 4 ? "" : styles.error} />
                    </label>
                    <label className={styles.label}>
                        <span>Ort</span>
                        <input type="text" name="city" value={address.city} onChange={change} maxLength={64} />
                    </label>
                    <label className={styles.label}>
                        <span>GLN</span>
                        <input type="text" name="gln" value={address.gln} onChange={change} maxLength={13} className={address.gln === "" || validateGLN(address.gln) ? "" : styles.error} />
                    </label>
                    <FooterButtons>
                        <button type="button" onClick={cancelEdit}>
                            Abbrechen
                        </button>
                        <button type="button" onClick={saveAddress} disabled={disabled}>
                            Speichern
                        </button>
                    </FooterButtons>
                </Fragment>
            ) : (
                <Fragment>
                    {name && (
                        <Fragment>
                            {name}
                            <br />
                        </Fragment>
                    )}
                    {street}
                    <br />
                    {street2 !== "" && (
                        <Fragment>
                            {street2}
                            <br />
                        </Fragment>
                    )}
                    {zip} {city}
                    {gln !== "" && (
                        <Fragment>
                            <br />
                            <br />
                            GLN: {gln}
                        </Fragment>
                    )}
                    <FooterButtons>
                        <button type="button" onClick={() => setEdit(true)}>
                            Bearbeiten
                        </button>
                    </FooterButtons>
                </Fragment>
            )}
        </Fieldset>
    );
};

export default Address;
