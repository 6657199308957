import { Fragment } from "react";
import { useSelector } from "react-redux";

import styles from "./Booked.module.css";
import Points from "../../../../Display/Points/Points";
import ShowBooked from "./ShowBooked/ShowBooked";

import { ReactComponent as ArrowRight } from "../../../../../assets/images/arrowRight2.svg";
import { ReactComponent as RollingEyes } from "../../../../../assets/images/rollingEyes.svg";

const Booked = ({ booked, points, pointsToReach }) => {
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);

    if (!serviceTemplates) {
        return null;
    }

    if (booked.length === 0) {
        return <i>Keine</i>;
    }

    return (
        <Fragment>
            <div className={styles.header}>
                <ArrowRight className={styles.arrow} /> <Points>{points}</Points>
                {Math.abs(100 - (100 / pointsToReach) * points) > 5 && (
                    <span title="Prozentuale Abweichung > 5%">
                        <ArrowRight className={styles.arrow} /> <RollingEyes className={styles.rollingEyes} />
                    </span>
                )}
            </div>
            <div className={styles.booked}>
                {booked.map((entry) => {
                    const serviceTemplate = serviceTemplates.find((serviceTemplate) => serviceTemplate.id === entry.serviceTemplateId);
                    return <ShowBooked key={entry.id} isFavorite={entry.isFavorite} startDay={entry.start} endDay={entry.end} serviceTemplate={serviceTemplate} points={entry.points} chance={entry.chance} includesPublicHolidays={entry.includesPublicHolidays} />;
                })}
            </div>
        </Fragment>
    );
};

export default Booked;
