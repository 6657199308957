import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import appReducer from "../features/app/appSlice";
import messageReducer from "../features/message/messageSlice";
import userDataReducer from "../features/userData/userDataSlice";
import regionReducer from "../features/region/regionSlice";
import categoryReducer from "../features/category/categorySlice";
import geographyPlanReducer from "../features/geographyPlan/geographyPlanSlice";
import rosterReducer from "../features/roster/rosterSlice";
import askReducer from "../features/ask/askSlice";
import serviceTemplateReducer from "../features/serviceTemplate/serviceTemplateSlice";
import valuesReducer from "../features/values/valuesSlice";
import personsReducer from "../features/persons/personsSlice";
import personReducer from "../features/person/personSlice";
import publicHolidayReducer from "../features/publicHoliday/publicHolidaySlice";
import serviceCircleReducer from "../features/serviceCircle/serviceCircleSlice";
import medicalPracticesReducer from "../features/medicalPractices/medicalPracticesSlice";
import medicalPracticeReducer from "../features/medicalPractice/medicalPracticeSlice";
import personInformationReducer from "../features/personInformation/personInformationSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		app: appReducer,
		category: categoryReducer,
		message: messageReducer,
		userData: userDataReducer,
		regions: regionReducer,
		geographyPlan: geographyPlanReducer,
		roster: rosterReducer,
		ask: askReducer,
		serviceTemplate: serviceTemplateReducer,
		values: valuesReducer,
		persons: personsReducer,
		person: personReducer,
		publicHoliday: publicHolidayReducer,
		serviceCircle: serviceCircleReducer,
		medicalPractices: medicalPracticesReducer,
		medicalPractice: medicalPracticeReducer,
		personInformation: personInformationReducer
	},
	middleware: [...getDefaultMiddleware({ immutableCheck: false })]
});
