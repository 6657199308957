import { useState, useEffect } from "react";

import TimeBar from "./TimeBar/TimeBar";
import TimeLegend from "./TimeLegend/TimeLegend";

import { displayTimeRange } from "../../../../shared/functions";
import { useDispatch, useSelector } from "react-redux";
import { createGeographyPlanTime, deleteGeographyPlanTime, updateGeographyPlanTime, selectTime } from "../../../../features/geographyPlan/geographyPlanSlice";
import { ask, clear } from "../../../../features/ask/askSlice";

import * as askTypes from "../../../../shared/askTypes";

import styles from "./TimeSelector.module.css";
import SmallPanel from "../../../../hoc/SmallPanel/SmallPanel";

const maxHeight = 480;

const TimeSelector = ({ planId, locked }) => {
	const [isDragging, setIsDragging] = useState(false);
	const selectedTimeId = useSelector((state) => state.geographyPlan.selectedTimeId);
	const answer = useSelector((state) => state.ask.answer);
	const times = [...useSelector((state) => state.geographyPlan.plan.times)];
	const dispatch = useDispatch();

	times.sort((a, b) => a.start - b.start);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_TIME_RANGE && answer.value !== null) {
				dispatch(deleteGeographyPlanTime(answer.value));
				dispatch(clear());
			}
		}
	}, [answer, planId, dispatch]);

	const addTime = (start, end) => {
		dispatch(createGeographyPlanTime({ start, end }));
	};

	const updateTime = (id, start, end) => {
		dispatch(updateGeographyPlanTime({ id, start, end }));
	};

	const select = (id) => {
		if (selectedTimeId !== id) {
			dispatch(selectTime(id));
		}
	};

	const deleteTime = (id) => {
		const theTime = times.find((entry) => entry.id === id);
		dispatch(
			ask({
				type: askTypes.DELETE_TIME_RANGE,
				title: "Zeit löschen",
				question: "Möchtest Du den Zeitbereich «" + displayTimeRange(theTime.start, theTime.end) + "» wirklich löschen?",
				buttons: [
					{ label: "Abbrechen", answer: null },
					{ label: "Ja, löschen", answer: id }
				]
			})
		);
	};

	const prepareNewTimes = [];
	let newTimes = null;
	if (!locked && !isDragging) {
		if (!times.length) {
			prepareNewTimes.push({
				start: 0,
				end: 24
			});
		} else {
			prepareNewTimes.push({
				start: 0,
				end: null
			});
			times.forEach((time) => {
				if (prepareNewTimes[prepareNewTimes.length - 1].end === null) {
					prepareNewTimes[prepareNewTimes.length - 1].end = time.start;
					prepareNewTimes.push({
						start: time.end,
						end: null
					});
				}
			});
			prepareNewTimes[prepareNewTimes.length - 1].end = 24;
		}
		newTimes = prepareNewTimes
			.filter((entry) => entry.start !== entry.end)
			.map((time) => {
				const start = (maxHeight / 24) * time.start;
				const style = {
					top: start.toString() + "px",
					height: (maxHeight / 24) * time.end - start + "px"
				};
				return <div key={time.start} className={styles.newTime} style={style} onClick={() => addTime(time.start, time.end)}></div>;
			});
	}

	const resultTimes = [];

	if (times) {
		times.forEach((time, index) => {
			let min = 0;
			let max = 24;
			if (index) {
				min = times[index - 1].end;
			}
			if (times.length > index + 1) {
				max = times[index + 1].start;
			}
			resultTimes.push({ ...time, min: min, max: max });
		});
	}

	return (
		<SmallPanel title="Zeit">
			<div className={styles.dateTimePlanContent}>
				<div>
					<TimeLegend />
					{newTimes}
					{resultTimes.map((entry, index) => (
						<TimeBar
							key={entry.start}
							index={index}
							id={entry.id}
							startTime={entry.start}
							endTime={entry.end}
							selectTime={select}
							selected={selectedTimeId === entry.id}
							updateTime={updateTime}
							deleteTime={deleteTime}
							maxHeight={maxHeight}
							min={entry.min}
							max={entry.max}
							setIsDragging={setIsDragging}
							isDragging={isDragging}
							locked={locked}
						/>
					))}
				</div>
			</div>
		</SmallPanel>
	);
};

export default TimeSelector;
