import { useEffect, useState } from "react";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import FieldLabel from "../../../../ui/FieldLabel/FieldLabel";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";

import styles from "./EditSubscription.module.css";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import { useDispatch } from "react-redux";
import { updateRosterSubscribtion, subscribeToRoster, unsubscribeFromRoster } from "../../../../features/roster/rosterSlice";
import Error from "../../../../ui/Error";
import { setError } from "../../../../features/message/messageSlice";
import { formatDate, getDateObjectFromMySqlDate, getNfdLoadFromWorkLoad } from "../../../../shared/functions";
import HideableOptions from "../../../../hoc/HideableOptions/HideableOptions";

const subscriptionObject = {
    firstname: "",
    lastname: "",
    birthdate: "",
    workload: 0,
    id: "",
    personId: 0,
    minAmount: "",
    maxAmount: "",
    balance: "0",
    canLockOutEvening: false,
    canLockOutEveningDisabled: false,
    lockWeekDays: "",
    lockWeekendDays: "",
    canLockPublicHolidays: false
};

const EditSubscription = ({ close, subscriptionData, rosterPeriodId, serviceByDoctor }) => {
    const [subscription, setSubscription] = useState({ ...subscriptionObject, ...subscriptionData });
    const [removeFromRoster, setRemoveFromRoster] = useState(false);
    const dispatch = useDispatch();
    const addToRoster = subscription.id === "";

    useEffect(() => {
        const errors = [];
        if (subscriptionData.error) {
            errors.push(...subscriptionData.error.split("\n"));
        }
        if (subscriptionData.notInServiceCircle) {
            errors.push(`${subscriptionData.firstname} ${subscriptionData.lastname} macht keinen Dienst in diesem Dienstkreis!`);
        }
        errors.length > 0 && dispatch(setError(errors));
        if (subscription.id === "" && (subscription.canLockOutEveningDisabled || !serviceByDoctor)) {
            setSubscription((prev) => ({ ...prev, canLockOutEvening: serviceByDoctor, canLockOutEveningDisabled: true }));
        }
    }, [dispatch, subscriptionData.notInServiceCircle, subscriptionData.firstname, subscriptionData.lastname, subscriptionData.error, subscription.canLockOutEveningDisabled, subscription.id, serviceByDoctor]);

    const change = (e) => {
        const fieldname = e.target.name;
        const fieldvalue = e.target.value;
        if (fieldvalue.match(/^\d*$/)) {
            setSubscription((prev) => ({ ...prev, [fieldname]: fieldvalue }));
        }
    };

    const changeBalance = (e) => {
        const fieldvalue = e.target.value;
        if (fieldvalue.match(/^-?\d*\.?\d*$/)) {
            setSubscription((prev) => ({ ...prev, balance: fieldvalue }));
        }
    };

    const save = () => {
        if (removeFromRoster) {
            dispatch(unsubscribeFromRoster({ rosterPeriodId, subscriptionId: subscription.id }));
        } else {
            dispatch(updateRosterSubscribtion({ rosterPeriodId, subscriptionId: subscription.id, data: subscription }));
        }
    };

    const add = () => {
        dispatch(subscribeToRoster({ rosterPeriodId, personId: subscription.personId, data: subscription }));
    };

    const hasValue = (value) => {
        const result = !(value === "" || value === "0" || value === 0);
        //debugger;
        return result;
    };

    return (
        <Window>
            <Panel title="Diensteinstellungen" size="medium">
                <Error />
                <form className={styles.content}>
                    <div className={styles.rows}>
                        {!addToRoster && (
                            <div>
                                <CheckBox checked={removeFromRoster} name={`${subscriptionData.firstname} ${subscriptionData.lastname} aus dem Dienstplan entfernen`} set={(value) => setRemoveFromRoster(value)} />
                            </div>
                        )}
                        {!removeFromRoster && (
                            <>
                                <div className={styles.cols}>
                                    <FieldLabel label="Arzt">
                                        {subscriptionData.firstname} {subscriptionData.lastname}
                                    </FieldLabel>
                                    <FieldLabel label="Notfalldienstpensum">{getNfdLoadFromWorkLoad(subscription.workload)}%</FieldLabel>
                                    <FieldLabel label="Geburtsdatum">{formatDate(getDateObjectFromMySqlDate(subscription.birthdate))}</FieldLabel>
                                </div>
                                <div className={styles.cols}>
                                    <FieldLabel label="Pensum">
                                        <input className={styles.number} type="text" name="minAmount" value={subscription.minAmount} onChange={change} maxLength={4} placeholder="10 - 100" />%
                                    </FieldLabel>
                                    <FieldLabel label="Sperrtage/Woche">
                                        <input className={styles.number} type="text" name="lockWeekDays" value={subscription.lockWeekDays} onChange={change} maxLength={1} placeholder="0 - 5" />
                                    </FieldLabel>
                                    <FieldLabel label="Punktesaldo">
                                        <input className={styles.number} type="text" name="balance" value={subscription.balance} onChange={changeBalance} maxLength={5} />
                                    </FieldLabel>
                                </div>
                                <FieldLabel label="Kann Abenddienst sperren">
                                    <CheckBox checked={subscription.canLockOutEvening} value={true} name="Ja" disabled={subscription.canLockOutEveningDisabled} set={(lock) => setSubscription((prev) => ({ ...prev, canLockOutEvening: lock }))} />
                                </FieldLabel>
                                <HideableOptions
                                    title="Weitere Optionen"
                                    show={hasValue(subscription.maxAmount) || hasValue(subscription.lockWeekendDays) || subscription.canLockPublicHolidays}
                                    disabled={hasValue(subscription.maxAmount) || hasValue(subscription.lockWeekendDays) || subscription.canLockPublicHolidays}
                                >
                                    <div className={styles.cols}>
                                        <FieldLabel label="Maximales Pensum">
                                            <input className={styles.number} type="text" name="maxAmount" value={subscription.maxAmount} onChange={change} maxLength={4} />%
                                        </FieldLabel>

                                        <FieldLabel label="Wochenend-Sperrtage/Woche">
                                            <input className={styles.number} type="text" name="lockWeekendDays" value={subscription.lockWeekendDays} onChange={change} maxLength={1} placeholder="0 - 2" />
                                        </FieldLabel>

                                        <FieldLabel label="Kann Dienst an Feiertagen sperren">
                                            <CheckBox checked={subscription.canLockPublicHolidays} value={true} name="Ja" set={(lock) => setSubscription((prev) => ({ ...prev, canLockPublicHolidays: lock }))} />
                                        </FieldLabel>
                                    </div>
                                </HideableOptions>
                            </>
                        )}
                    </div>
                    <FooterButtons paddingTop>
                        <button type="button" onClick={close}>
                            Abbrechen
                        </button>
                        {addToRoster ? (
                            <button type="button" onClick={add}>
                                In Dienstplan aufnehmen
                            </button>
                        ) : (
                            <button type="button" onClick={save}>
                                OK
                            </button>
                        )}
                    </FooterButtons>
                </form>
            </Panel>
        </Window>
    );
};

export default EditSubscription;
