import { useEffect } from "react";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Panel from "../../../../hoc/Panel/Panel";
import Account from "../Account/Account";
import Rosters from "../Rosters/Rosters";
import ServiceAvailability from "../ServiceAvailability/ServiceAvailability";
import Log from "../Log/Log";
import SettingsBrowser from "../../../../hoc/SettingsBrowser/SettingsBrowser";
import Window from "../../../../hoc/Window/Window";

import * as routes from "../../../../shared/routes";
import { addPersonNote, updatePersonNote, clearPerson, getPerson } from "../../../../features/person/personSlice";
import Notes from "../../../Notes/Notes";

const pages = [
	{
		path: routes.DOCTOR_ACCOUNT,
		element: <Account />,
		name: "Konto",
		onlyDoctor: false
	},
	{
		path: routes.DOCTOR_ROSTERS,
		element: <Rosters />,
		name: "Dienstpläne",
		onlyDoctor: true
	},
	{
		path: routes.DOCTOR_SERVICE_REACHABILITY,
		element: <ServiceAvailability />,
		name: "Diensterreichbarkeit",
		onlyDoctor: true
	},
	{
		path: routes.DOCTOR_LOG,
		element: <Log />,
		name: "Log",
		onlyDoctor: false
	}
];

const PersonDetailSelector = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const person = useSelector((state) => state.person.person);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPerson(id));
		return () => {
			dispatch(clearPerson());
		};
	}, [dispatch, id]);

	const baseUrl = routes.PERSONS + "/" + id;

	const showPages = pages.filter((entry) => !entry.onlyDoctor || (person && person.type === "doctor"));

	const showRoutes = useRoutes([
		{
			path: "",
			element: <SettingsBrowser menus={showPages} baseUrl={baseUrl} />,
			children: showPages
		}
	]);

	if (!person) {
		return null;
	}

	const close = () => {
		navigate(routes.PERSONS);
	};

	const addNote = (note) => {
		dispatch(addPersonNote({ personId: id, data: { ...note } }));
	};

	const updateNote = (noteId, marked) => {
		dispatch(updatePersonNote({ personId: id, id: noteId, data: { marked } }));
	};

	const notes = <Notes notes={person.notes} addNote={addNote} updateNote={updateNote} />;

	return (
		<Window>
			<Panel title="Person bearbeiten" size="large" close={close} notes={notes}>
				{showRoutes}
			</Panel>
		</Window>
	);
};

export default PersonDetailSelector;
