import { useState } from "react";
import styles from "./HideableOptions.module.css";

const HideableOptions = ({ title, show, disabled, children }) => {
    const [showOptions, setShowOptions] = useState(show);

    const classNames = [styles.fieldset];
    !showOptions && classNames.push(styles.hide);

    return (
        <fieldset className={classNames.join(" ")}>
            <legend>
                <label>
                    <input type="checkbox" checked={showOptions} onChange={() => setShowOptions((prev) => !prev)} disabled={disabled} />
                    {title}
                </label>
            </legend>
            <div>{children}</div>
        </fieldset>
    );
};

export default HideableOptions;
